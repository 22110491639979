import React from "react";

export const TransactionItemOptions = () => {
  return (
    <>
      <option value={0} disabled>
        Item Name
      </option>
      <option value={undefined}></option>
      <option value={"MEMBER CUCI"}>CUCI MEMBER</option>
      <option value={"EXTEND MEMBER%"}>EXTEND MEMBER</option>
      <option value={"NEW MEMBER%"}>NEW MEMBER</option>
    </>
  );
};
