import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import { MembershipDurationOptions } from "../MembershipDurationOptions.component";
import {
  IReturnCreateOrUpdateMembership,
  IReturnGetCustomers,
  IReturnGetMembeship,
  carwashApi_MembershipActivation,
  carwashApi_updateMembership,
} from "../../../carwashApi";
import {
  flattenErrorFieldReactHookForm,
  thousandCommas,
} from "../../../utilities";
import { DateTime } from "luxon";
import { isString } from "lodash";
import { useForm, Controller } from "react-hook-form";
import ArrayNomorKartusFields from "../Component/ArrayNomorKartusFields.component";
import ArrayCarFields from "../Component/ArrayCarFields.component";
import { checkCarMembershipFn } from "../Hooks/useCheckCarMembership.hooks";
import ReactDatePicker from "react-datepicker";

const expire_date_calculation = (p: {
  membership: IReturnGetMembeship;
  issue_date: Date;
  duration: number;
}) => {
  const { membership, issue_date, duration } = p;
  if (membership && new Date(membership.expire_date) > issue_date) {
    return DateTime.fromJSDate(new Date(membership.expire_date))
      .plus({
        month: duration,
      })
      .set({
        hour: 23,
        minute: 59,
        second: 50,
      })
      .toJSDate();
  } else {
    return DateTime.fromJSDate(issue_date)
      .plus({ month: duration })
      .minus({ day: 1 })
      .set({ hour: 23, minute: 59, second: 50 })
      .toJSDate();
  }
};
export const MembershipExtendButton = (p: {
  membership: IReturnGetMembeship;
  onExtendSuccess: (r: IReturnCreateOrUpdateMembership) => void;
}) => {
  const [show, setShow] = React.useState(false);
  // const [formData, setFormData] = React.useState<{
  //   duration: number;
  //   price: string;
  //   expireDate: Date | undefined;
  // }>({ duration: 0, price: "", expireDate: undefined });
  const form = useForm<{
    duration: number;
    price: string;
    issueDate: Date;
    expireDate: Date | undefined;
    membership_nomor_kartus?: { nomor: string }[];
    cars: {
      plate_number: string;
      car_brand: string;
      car_color: string;
    }[];
  }>();

  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShow(true);
          form.setValue("duration", 0);
          form.setValue("price", "");
          form.setValue(
            "issueDate",
            DateTime.now()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toJSDate()
          );
          form.setValue("expireDate", undefined);
          form.setValue(
            "membership_nomor_kartus",
            p.membership.membership_info_kartus?.map((v) => ({
              nomor: v.nomor_kartu,
            })) || []
          );
          form.setValue(
            "cars",
            p.membership.cars?.map((c) => ({
              car_brand: c.car_brand,
              car_color: c.car_color,
              plate_number: c.plate_number,
            })) || []
          );
        }}
      >
        Extend
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Extend Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup as={Row} controlId="duration">
            <FormLabel column>Duration</FormLabel>
            <Col>
              <FormSelect
                defaultValue={0}
                // value={formData.duration}
                {...form.register("duration", { valueAsNumber: true })}
                onChange={(e) => {
                  const duration = parseInt(e.currentTarget.value);
                  form.setValue("duration", duration);
                  form.setValue(
                    "expireDate",
                    expire_date_calculation({
                      membership: p.membership,
                      duration: duration,
                      // issue_date: DateTime.now().toJSDate(),
                      issue_date: form.getValues("issueDate"),
                    })
                  );
                }}
              >
                <MembershipDurationOptions />
              </FormSelect>
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="price">
            <FormLabel column>Members Price</FormLabel>
            <Col>
              <Controller
                name="price"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormControl
                      {...field}
                      onChange={(e) => {
                        field.onChange(
                          thousandCommas(
                            e.currentTarget.value.replaceAll(",", "")
                          )
                        );
                      }}
                      placeholder="Price"
                      // value={field.value}
                    />
                  );
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="Issue Date">
            <FormLabel column>Issue Date</FormLabel>
            <Col>
              <Controller
                control={form.control}
                name="issueDate"
                render={({ field }) => {
                  return (
                    <ReactDatePicker
                      placeholderText="Issue Date"
                      dateFormat={"yyyy-MM-dd"}
                      onChange={(date) => {
                        const duration = form.getValues("duration");
                        if (date) {
                          const expireDate = expire_date_calculation({
                            membership: p.membership,
                            duration,
                            issue_date: date,
                          });
                          form.setValue("expireDate", expireDate);
                        }
                        field.onChange(date);
                      }}
                      minDate={new Date()}
                      maxDate={DateTime.now().plus({ month: 1 }).toJSDate()}
                      selected={field.value}
                    />
                  );
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="Expire Date">
            <FormLabel column>Expire Date</FormLabel>
            <Col>
              <Controller
                control={form.control}
                name="expireDate"
                render={({ field }) => {
                  return (
                    <FormControl
                      {...field}
                      disabled
                      value={
                        field.value
                          ? DateTime.fromJSDate(field.value).toFormat(
                              "yyyy-MM-dd"
                            )
                          : ""
                      }
                    />
                  );
                }}
              />
            </Col>
          </FormGroup>
          <br />
          <FormGroup>
            <ArrayNomorKartusFields form={form} />
          </FormGroup>
          <div
            style={{
              width: "100%",
              borderBottom: "1px",
              borderBottomStyle: "dashed",
              display: "inline-block",
            }}
          ></div>
          <br />
          <br />
          <FormGroup>
            <ArrayCarFields form={form} hide={["car_color", "car_brand"]} />
          </FormGroup>
          <Button
            onClick={(event) => {
              form
                .handleSubmit(async (formData) => {
                  console.log(formData);
                  const checkCarMembership = await checkCarMembershipFn({
                    cars: formData.cars,
                    target_customer_id: p.membership.customer?.customer_id,
                  });
                  const nokCar = checkCarMembership.filter(
                    (c) => c.status === "NOK"
                  );
                  if (nokCar.length > 0) {
                    nokCar.forEach((c) => {
                      const index = formData.cars.findIndex(
                        (car) => car.plate_number === c.plate_number
                      );

                      form.setError(`cars.${index}.plate_number`, {
                        message: c.message,
                      });
                    });
                    return Promise.reject();
                  }

                  const resUpdateMembership = await carwashApi_updateMembership(
                    {
                      membership_id: p.membership.membership_id,
                      expire_date: formData.expireDate,
                      // issue_date: DateTime.now()
                      //   .set({ hour: 0, minute: 0, second: 0 })
                      //   .toJSDate(),
                      issue_date: formData.issueDate,
                      membership_nomor_kartus:
                        formData.membership_nomor_kartus
                          ?.map((n) => n.nomor)
                          .filter((n) => !!n) || [],
                      duration: formData.duration,
                      price: parseInt(formData.price.replaceAll(",", "")),
                      // plate_numbers: p.membership.cars?.map((c) => c.plate_number),
                      plate_numbers: formData.cars?.map((c) => c.plate_number),
                    }
                  ).catch((e) => {
                    return Promise.reject(e);
                  });

                  if (formData.issueDate.valueOf() <= new Date().valueOf()) {
                    await carwashApi_MembershipActivation({
                      membership_id: resUpdateMembership.data.membership_id,
                    }).catch((e) => {
                      return Promise.reject(e);
                    });
                  }

                  alert("Extend Membership OK");
                  setShow(false);
                  form.reset();
                  p.onExtendSuccess(resUpdateMembership.data);
                })(event)
                .catch((e) => {
                  const errField = flattenErrorFieldReactHookForm(
                    form.formState.errors
                  );

                  const firstErrField = errField[0];

                  alert(
                    firstErrField
                      ? firstErrField.key + ": " + firstErrField.message
                      : e?.response?.data?.message
                      ? isString(e.response.data.message)
                        ? e.response.data.message
                        : e.response.data.message.issues?.[0]?.path?.[0] +
                          ": " +
                          e.response.data.message.issues?.[0]?.message
                      : e
                  );
                });
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
