import { useCallback } from "react";
import { carwashApi_createCar } from "../../../carwashApi";

interface checkCarParams {
  plate_number: string;
  car_brand: string;
  car_color: string;
}
interface checkCarMembershipReturn {
  plate_number: string;
  status: "OK" | "NOK";
  message: string;
}

export const checkCarMembershipFn = async (p: {
  cars: checkCarParams[];
  target_customer_id?: string;
}) => {
  return Promise.all(
    p.cars.map(async (dataCar, index): Promise<checkCarMembershipReturn> => {
      // const car = await carwashApiCreateOrGetCar.mutateAsync({
      //   plate_number: dataCar.plate_number,
      //   car_color: dataCar.car_color,
      //   car_brand: dataCar.car_brand,
      // });
      const res = await carwashApi_createCar({
        plate_number: dataCar.plate_number,
        color: dataCar.car_color,
        car_brand: dataCar.car_brand,
      });
      const car = res.data;

      const carMemberships = car.memberships?.find(
        (m) => m.member_type === "cuci-unlimited"
      );

      if (
        carMemberships &&
        (carMemberships.expire_date > new Date() ||
          carMemberships.status === "active") &&
        carMemberships.customer.customer_id !== p.target_customer_id
      ) {
        // p.setError(`cars.${index}.plate_number`, {
        //   message: "Plate Number is bound in another active members",
        // });
        return {
          message: `Plate Number ${dataCar.plate_number} is bound in another active members`,
          plate_number: dataCar.plate_number,
          status: "NOK",
        };
      }
      return {
        message: "",
        plate_number: dataCar.plate_number,
        status: "OK",
      };
    })
  );
};

export const useCheckCarMembershipCuciUnlimited = () => {
  const cb = useCallback(checkCarMembershipFn, []);
  return cb;
};
