import { flattenDeep, get, values } from "lodash";
import { FieldErrors } from "react-hook-form";

export const thousandCommas = (numString: string) => {
  return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const flattenErrorFieldReactHookForm = (
  fieldErrors: FieldErrors,
  _obj: { key: string; message: string }[] = [],
  _parentKey: string = ""
): { key: string; message: string }[] => {
  // Map through fieldErrors to extract messages
  Object.entries(fieldErrors).forEach(([key, error]) => {
    const currentKey = _parentKey ? `${_parentKey}.${key}` : key;

    if (typeof error === "object" && get(error, "message")) {
      // If an error message exists, push it to the results
      _obj.push({ key: currentKey, message: String(get(error, "message")) });
    } else if (typeof error === "object") {
      // If it’s an object, recursively call the function
      flattenErrorFieldReactHookForm(error as any, _obj, currentKey);
    }
  });

  return _obj; // Return the accumulated messages
};
