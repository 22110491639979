import {
  useFieldArray,
  UseFormReturn,
  Path,
  ArrayPath,
  UseFieldArrayReturn,
  FieldArray,
} from "react-hook-form";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Row,
  Col,
  Button,
} from "react-bootstrap";

// Define the structure of each membership field
interface MembershipFieldNomorKartu {
  nomor: string;
}

// Base interface that requires the membership field array
interface BaseMembershipNomorKartuForm {
  membership_nomor_kartus?: MembershipFieldNomorKartu[];
}

// Create the reusable ArrayNomorKartusFields component
const ArrayNomorKartusFields = <T extends BaseMembershipNomorKartuForm>({
  form,
}: {
  form: UseFormReturn<T>; // Accepting form with extended type
}) => {
  // Use useFieldArray to manage the dynamic fields
  const membershipKartusField = useFieldArray<T>({
    control: form.control,
    name: "membership_nomor_kartus" as ArrayPath<T>, // Ensure correct path for the array
  });

  return (
    <>
      {membershipKartusField.fields.map((item, index) => (
        <FormGroup
          as={Row}
          controlId={`membership_nomor_kartus.${index}.nomor`}
          key={item.id} // Ensure each item has a unique key
        >
          <FormLabel column>Nomor Kartu</FormLabel>
          <Col>
            <FormControl
              {...form.register(
                `membership_nomor_kartus.${index}.nomor` as Path<T>
              )}
              placeholder="Nomor Kartu"
            />
          </Col>
        </FormGroup>
      ))}
      <br />
      <Button
        size="sm"
        onClick={() => {
          const x: MembershipFieldNomorKartu = { nomor: "" };
          membershipKartusField.append(x as FieldArray<T>); // Add a new empty field
        }}
        style={{ float: "right" }}
      >
        Add Kartu
      </Button>
    </>
  );
};

export default ArrayNomorKartusFields;
