import React from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import {
  ArrayPath,
  FieldArray,
  FieldErrors,
  Path,
  PathValue,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";
import { useWatchAndQuery } from "../../../hooks/useWatchAndQuery";
import { carwashApi_getCars } from "../../../carwashApi";
import { If } from "../../If.component";

// Define the structure of each membership field
interface MembershipFieldCar {
  plate_number: string;
  car_brand: string;
  car_color: string;
}

// Base interface that requires the membership field array
interface BaseMembershipCarForm {
  cars: MembershipFieldCar[];
}

const ArrayCarFieldsChildren = <T extends BaseMembershipCarForm>(p: {
  form: UseFormReturn<T>;
  hide?: Array<keyof BaseMembershipCarForm["cars"][number]>;
  index: number;
  fieldArray: UseFieldArrayReturn<T>;
}) => {
  const carwashApiGetCarByPlateNumberField = useWatchAndQuery({
    formHook: p.form,
    watchKey: `cars.${p.index}.plate_number` as Path<T>,
    queryKey: ["customer-by-plate-number", p.index],
    queryFn: async (watchPlateNumber) => {
      const resGetCustomer = await carwashApi_getCars({
        plate_number: watchPlateNumber.toString(),
      });
      const dataCustomer = resGetCustomer.data.data?.[0];
      return dataCustomer;
    },
  });

  React.useEffect(() => {
    const foundCarData = carwashApiGetCarByPlateNumberField.data;
    // console.log(foundCarData);
    if (foundCarData) {
      p.form.setValue(
        `cars.${p.index}.car_brand` as Path<T>,
        (foundCarData.car_brand || "") as PathValue<T, Path<T>>
      );
      p.form.setValue(
        `cars.${p.index}.car_color` as Path<T>,
        (foundCarData.car_color || "") as PathValue<T, Path<T>>
      );
    }
  }, [carwashApiGetCarByPlateNumberField.data]);

  const formStateError = React.useMemo(() => {
    return p.form.formState.errors as FieldErrors<BaseMembershipCarForm>;
  }, [p.form.formState.errors]);

  return (
    <Container
      fluid
      style={{ borderBottom: "1px", borderBottomStyle: "dashed" }}
    >
      <br />
      <Row>
        <Col>
          <FormGroup as={Row} controlId={`plate_number.${p.index}`}>
            <FormLabel column>Plate Number</FormLabel>
            <Col>
              <FormControl
                {...p.form.register(`cars.${p.index}.plate_number` as Path<T>, {
                  required: true,
                })}
                placeholder="Plate Number"
              />
            </Col>
          </FormGroup>
          <If condition={!p.hide?.includes("car_brand")}>
            <br />
            <FormGroup as={Row} controlId={`car_brand.${p.index}`}>
              <FormLabel column>Car Brand</FormLabel>
              <Col>
                <FormControl
                  {...p.form.register(`cars.${p.index}.car_brand` as Path<T>)}
                  placeholder="Car Brand"
                />
              </Col>
            </FormGroup>
          </If>
          <If condition={!p.hide?.includes("car_color")}>
            <br />
            <FormGroup as={Row} controlId={`car_color.${p.index}`}>
              <FormLabel column>Car Color</FormLabel>
              <Col>
                <FormControl
                  {...p.form.register(`cars.${p.index}.car_color` as Path<T>)}
                  placeholder="Car Color"
                />
              </Col>
            </FormGroup>
          </If>
        </Col>
      </Row>
      <br />
      <Row>
        <Col />
        <Col lg="4">
          <Button
            size="sm"
            onClick={() => {
              p.fieldArray.remove(p.index);
            }}
            style={{ float: "right" }}
          >
            Remove
          </Button>
        </Col>
      </Row>

      {formStateError.cars?.[p.index] ? (
        <p style={{ color: "red" }}>
          {formStateError.cars?.[p.index]?.plate_number?.message}
        </p>
      ) : (
        ""
      )}

      <br />
    </Container>
  );
};

const ArrayCarFields = <T extends BaseMembershipCarForm>(p: {
  form: UseFormReturn<T>;
  hide?: Array<keyof BaseMembershipCarForm["cars"][number]>;
}) => {
  const membershipCarsField = useFieldArray<T>({
    name: "cars" as ArrayPath<T>,
    control: p.form.control,
    shouldUnregister: true,
  });

  return (
    <>
      {membershipCarsField.fields.map((item, index) => (
        <ArrayCarFieldsChildren
          form={p.form}
          hide={p.hide}
          index={index}
          fieldArray={membershipCarsField}
          key={index}
        ></ArrayCarFieldsChildren>
      ))}
      <br />
      <Button
        size="sm"
        onClick={() => {
          const x: MembershipFieldCar = {
            plate_number: "",
            car_brand: "",
            car_color: "",
          };
          membershipCarsField.append(x as FieldArray<T, ArrayPath<T>>);
        }}
        style={{ float: "right" }}
      >
        Add Car
      </Button>
    </>
  );
};

export default ArrayCarFields;
